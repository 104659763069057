import React, { useLayoutEffect, useRef, useState } from "react";
import Footer from "../Footer/Footer";
import GetInTouch from "../GetInTouch/GetInTouch";
import Inspired from "../Inspired/Inspired";
import Landing from "../Landing/Landing";
import MenuSection from "../MenuSection/MenuSection";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import PhotoGallery from "../PhotoGallery/PhotoGaller";

//
import "./Home.css";
//import HomeModal from "../HomeModal/HomeModal";
import ResultMoving from "../ResultMoving/ResultMoving";

function Home() {
    const [isModal, setIsModal] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isShow, steIsShow] = useState(false);
    const handleScroll = (e) => {
        console.log(e);
        if (e.target.scrollTop > 0 && !isScrolled) {
            setIsScrolled(true);
        }
        if (e.target.scrollTop === 0) {
            setIsScrolled(false);
        }
    };
    const handleClick = () => {
        steIsShow(!isShow);
        console.log(isShow);
    };

    const handleClose = () => {
        setIsModal(false);
    };

    return (
        <div className="home_main" onScroll={handleScroll}>
            <Navbar isScrolled={isScrolled} handleClick={handleClick} />
            <NavbarMenu isShow={isShow} handleClick={handleClick} />
            <Landing video={true} />
            {/* <ResultMoving /> */}
            <p
                className="school_name"
                style={{
                    color: "#0076bd",
                    textAlign: "center",
                    marginTop: "20px",
                }}
            >
                Hermann Gmeiner School, Varanasi
            </p>
            <MenuSection />
            <Inspired />
            <GetInTouch />
            <PhotoGallery />
            <Footer />
            {/* <HomeModal show={isModal} onHide={handleClose} /> */}
        </div>
    );
}

export default Home;
